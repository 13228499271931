import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { resetAppState } from 'App/redux/appSlice';
import { signedOut, switchingAccount } from 'Auth/redux/authSlice';
import { notify } from '_common/components/ToastSystem';
import { EditorService } from '_common/services';
import { paths } from '_types/api';

const SLICE_NAME = 'EDITOR_IMAGE_UPLOAD';

type SliceState = {
  uploading: boolean;
  lengthComputable: boolean;
  loaded: number;
  total?: number;
};

const initialState: SliceState = {
  uploading: false,
  lengthComputable: false,
  loaded: 0,
  total: 1,
};

export const saveImage = createAsyncThunk<
  void,
  {
    params: { id: string; image: File };
    callback: (
      image: paths['/api/object/document/image/upload']['post']['responses']['200']['content']['application/json'],
    ) => void;
    errorCallback?: (error?: unknown) => void;
  }
>(`${SLICE_NAME}/saveImage`, async ({ params, callback, errorCallback }, { dispatch }) => {
  const config = {
    onUploadProgress: (progressEvent: Request.AxiosProgressEvent) => {
      const data = {
        lengthComputable: progressEvent.lengthComputable,
        loaded: progressEvent.loaded,
        total: progressEvent.total,
      };
      dispatch(imageUploadUpdated(data));
    },
  };

  try {
    const { data, status } = await new EditorService({ errorsExpected: [400] }).saveImage(
      params,
      config,
    );

    if (status !== 200) {
      notify({
        type: 'error',
        title: 'global.error',
        message: 'editor.errors.uploadImage',
      });
      errorCallback?.(new Error('Error uploading!'));
    } else {
      callback(data);
    }
  } catch (error) {
    if (EditorService.isAxiosError(error)) {
      if (error?.response?.status === 400) {
        notify({
          type: 'error',
          title: 'global.error',
          message: 'editor.errors.uploadImage',
        });
      } else {
        global.logger.captureException(error);
      }
    }
    errorCallback?.(error);
  }
});

const imageUploadSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    imageUploadUpdated: (state, action: PayloadAction<Omit<SliceState, 'uploading'>>) => {
      state.lengthComputable = action.payload.lengthComputable;
      state.loaded = action.payload.loaded;
      state.total = action.payload.total;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveImage.pending, (state) => {
        state.uploading = true;
      })
      .addCase(saveImage.fulfilled, (state) => {
        state.uploading = false;
        state.lengthComputable = false;
        state.loaded = 0;
        state.total = 1;
      })
      .addMatcher(isAnyOf(signedOut, resetAppState, switchingAccount), () => {
        return initialState;
      });
  },
});

export const { imageUploadUpdated } = imageUploadSlice.actions;

export default imageUploadSlice.reducer;
