import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeAndResetModal } from '_common/modals/ModalsSlice';

import Collapser from './Collapser/Collapser';

import { Modal, Button, Checkbox } from 'dodoc-design-system';

const MODAL = 'WOPIRedirectionModal';

const WOPIRedirectionModal = () => {
  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const app = useSelector((state) => state.modals[MODAL].app);
  const action = useSelector((state) => state.modals[MODAL].action);
  const id = useSelector((state) => state.modals[MODAL].id);
  const self = useSelector((state) => state.modals[MODAL].self);
  const userId = useSelector((state) => state.auth.userId);

  //local
  const [appName, setAppName] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    return () => {
      setIsChecked(false);
      setUrl('');
      setAppName('');
    };
  }, [isOpen]);

  useEffect(() => {
    if (app) {
      setAppName(`Microsoft ${app} Online`);
    }
  }, [app]);

  useEffect(() => {
    setUrl(`/extensions/wopi/${id}/${app}/${action}`);
  }, [app, action, id]);

  const close = () => {
    dispatch(closeAndResetModal(MODAL));
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleOnClick = () => {
    if (url) {
      if (isChecked) {
        localStorage.setItem(`${userId}-WOPI_ALLOW_ACCESS`, 'true');
      }

      window.open(url, self ? '_self' : '_blank');
    }
    close();
  };

  return (
    <Modal width="90rem" open={isOpen || false} onClose={close} testId="wopi-redirection">
      <Modal.Header onClose={close}>
        <FormattedMessage id="REDIRECTING_TO" /> {appName}
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="YOU_ARE_ALLOWING_APP_TO_HAVE_ACCESS_TO_FILE" values={{ appName }} />
        <Collapser />
        <Checkbox
          checked={isChecked ? 'checked' : 'unchecked'}
          size="small"
          onChange={handleOnChange}
          testId="do-not-show-this-message-again-checkbox"
        >
          <FormattedMessage id="DO_NOT_SHOW_THIS_MESSAGE_AGAIN" />
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="wopi-redirection-modal-cancel-button">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleOnClick}
          testId="wopi-redirection-modal-submit-button"
        >
          <FormattedMessage id="AGREE_AND_OPEN_FILE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WOPIRedirectionModal;
