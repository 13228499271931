import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import isEqual from 'lodash-es/isEqual';
import uniq from 'lodash-es/uniq';

import { notify } from '_common/components/ToastSystem';
import { GroupsService, InstanceService } from '_common/services';
import authApi, { selectCurrentUser, selectUserIsAdmin } from '_common/services/api/authority';

import { updateModal, closeAndResetModal } from '_common/modals/ModalsSlice';
import {
  addToList,
  singleSelection,
  removeFromList,
  clearSelection,
  listObjects,
  lazyLoad,
} from '_common/components/Table/TableSlice';
import { selectElementStatusList } from '_common/services/api/elementStatusApi';
import { paths } from '_types/api';
import objectApi from './objectApi';
import { ThemeProviderContext } from 'dodoc-design-system/build/types/ThemeProvider';
import { GHOST_USERS } from '_common/services/api/consts';
import ObjectApi from '_common/services/api/ObjectApi';

const SLICE_NAME = 'APP';

type AppSliceState = {
  data: ObjectDict;
  loading: {
    isOpen: boolean;
    message?: TranslationId;
    messageValues?: Record<string, any>;
  };
  currentPage: string; // restrict this type
  isTenantDeactivated: boolean;
  redirectValidSession: boolean;
  versionWarning: {
    isOpen: boolean;
  };
  adblock: boolean;
  platform: Platform;
  error: {
    status: number;
  };
  information: {
    actions: AuthoritySchemas['ThirdPartyActions'] | undefined;
    extra: { [key: string]: MyAny } | undefined;
    third_party: AuthoritySchemas['ThirdPartyObject'] | undefined | false;
  };
  unknownObjects: { [id in ObjectId]: ObjectId };
  connectivityIssues?: boolean;
  versionValidation?: boolean;
  theme: ThemeProviderContext['theme'];
};

type DueDateParams = {
  objectId: ObjectParams['objectId'];
  overdue?: ISODate;
  warning?: ISODate;
};

type GroupParams = {
  groupId: Group['id'];
  userId: UserId;
};

const INITIAL_STATE: AppSliceState = {
  data: {}, // App data (elements)
  loading: {
    isOpen: false,
  },
  currentPage: '',

  isTenantDeactivated: false,
  redirectValidSession: true,

  versionWarning: {
    isOpen: false,
  },
  adblock: false,
  platform: {
    mobile: false,
    browser: {
      chrome: false,
      ie: false,
      safari: false,
      firefox: false,
      edge: false,
      opera: false,
      operaMini: false,
      ieMobile: false,
    },
    os: {
      blackBerry: false,
      mac: false,
      iOS: false,
      windows: false,
      android: false,
      linux: false,
    },
  },
  error: {
    status: 0,
  },
  information: { actions: undefined, extra: undefined, third_party: undefined },
  unknownObjects: {},
  theme: 'lightBlue',
};

// #region AsyncThunks

export const getObjectData = createAsyncThunk(
  `${SLICE_NAME}/getObjectData`,
  async (
    {
      objectId,
      objectType,
      ignoreErrorStates,
    }: ObjectParams & { ignoreErrorStates?: (400 | 403 | 404)[] },
    { dispatch },
  ) => {
    try {
      const { data } = await new InstanceService({
        errorsExpected: [400, 403, 404],
      }).getObjectData({ objectId, objectType });

      // @ts-expect-error Generic endpoint
      const usersPermissions = Object.keys(data.permissions['users']);
      // @ts-expect-error Generic endpoint
      if (!usersPermissions.includes(data.creator)) {
        // @ts-expect-error Generic endpoint
        usersPermissions.push(data.creator);
      }
      // @ts-expect-error Generic endpoint
      if (!usersPermissions.includes(data.owner)) {
        // @ts-expect-error Generic endpoint
        usersPermissions.push(data.owner);
      }

      // @ts-expect-error Generic endpoint
      dispatch(addData({ [data.id]: data }));
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 400 && !ignoreErrorStates?.includes(400)) {
          dispatch(setBadRequestState());
        } else if (error.response?.status === 403 && !ignoreErrorStates?.includes(403)) {
          dispatch(setForbiddenState());
        } else if (error.response?.status === 404 && !ignoreErrorStates?.includes(404)) {
          dispatch(setNotFoundState());
        }
      }
    }
  },
);

// #region Tags

export const addTag = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  ObjectParams & { tag: Tag },
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(`${SLICE_NAME}/addTag`, async ({ objectId, objectType, tag }, { dispatch, getState }) => {
  try {
    await new InstanceService({ errorsExpected: [400] }).addTag(objectType, objectId, tag);

    const tags = getState().app.data[objectId].tags;
    dispatch(updateData({ objectId, data: { tags: [...tags, tag] } }));
  } catch (error) {
    if (InstanceService.isAxiosError(error)) {
      if (error.response?.status === 400 && error.response.data.tag[0] === 'duplicated') {
        notify({
          type: 'error',
          title: 'global.error',
          message: 'storage.browserSidebar.DUPLICATED_TAG',
        });
      }
    }
  }
});

export const removeTag = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  ObjectParams & { tag: Tag },
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(`${SLICE_NAME}/removeTag`, async ({ objectId, objectType, tag }, { dispatch, getState }) => {
  await new InstanceService().removeTag(objectType, objectId, tag);
  const tags = getState().app.data[objectId].tags.filter((element: typeof tag) => element !== tag);
  dispatch(updateData({ objectId, data: { tags } }));
});

// #endregion

// #region Element Details Operations

export const editDescription = createAsyncThunk(
  `${SLICE_NAME}/editDescription`,
  async (
    { objectId, objectType, newDescription }: ObjectParams & { newDescription: string },
    { dispatch },
  ) => {
    await new InstanceService().editDescription(objectId, objectType, newDescription);
    dispatch(updateData({ objectId, data: { description: newDescription } }));
  },
);

export const editOverdue = createAsyncThunk(
  `${SLICE_NAME}/editOverdue`,
  async ({ objectId, overdue }: DueDateParams, { dispatch }) => {
    try {
      const { data } = await new InstanceService({
        errorsExpected: [400],
      }).editOverdue(objectId, overdue);

      dispatch(
        updateData({
          objectId,
          // @ts-expect-error Missing endpoint type "/api/object/${id}/overdue/edit"
          data: { events: { due: overdue, warnings: data.events.warnings } },
        }),
      );
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 400 && error.response.data.overdue[0] === 'invalid') {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'notifications.defaultError',
          });
        }
      }
    }
  },
);

export const deleteOverdue = createAsyncThunk(
  `${SLICE_NAME}/deleteOverdue`,
  async ({ objectId, overdue }: DueDateParams, { dispatch }) => {
    await new InstanceService().editOverdue(objectId, overdue);
    dispatch(updateData({ objectId, data: { events: { due: '', warnings: [] } } }));
  },
);

export const saveDueDate = createAsyncThunk(
  `${SLICE_NAME}/saveDueDate`,
  async ({ objectId, warning }: DueDateParams, { dispatch }) => {
    try {
      const { data } = await new InstanceService({
        errorsExpected: [400],
      }).saveWarningDate(objectId, warning);

      // @ts-expect-error Missing endpoint type "/api/object/${id}/warning/add"
      dispatch(updateData({ objectId, data: { events: data.events } }));
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 400 && error.response.data.warning[0] === 'invalid') {
          notify({
            type: 'error',
            title: 'global.error',
            message: 'notifications.defaultError',
          });
        }
      }
    }
  },
);

export const deleteWarningDate = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  Omit<Required<DueDateParams>, 'overdue'>,
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(`${SLICE_NAME}/deleteWarningDate`, async ({ objectId, warning }, { dispatch, getState }) => {
  await new InstanceService().deleteWarningDate(objectId, warning);
  const events = getState().app.data[objectId].events;
  const newWarnings = events.warnings.filter(
    (element: typeof warning) => new Date(element).getTime() !== new Date(warning).getTime(),
  );

  dispatch(updateData({ objectId, data: { events: { ...events, warnings: [...newWarnings] } } }));
});

// #endregion

// #region Element Operations
type ConvertFileToArgs = {
  objectId: paths['/api/object/document/{object_id}/convert']['post']['parameters']['path']['object_id'];
  deleteSource: paths['/api/object/document/{object_id}/convert']['post']['requestBody']['content']['multipart/form-data']['delete_source'];
  objectType: 'document' | 'dopdf' | 'presentation';
};
export const convertFileTo = createAsyncThunk(
  `${SLICE_NAME}/convertFileTo`,
  async ({ objectId, deleteSource, objectType }: ConvertFileToArgs, { dispatch, getState }) => {
    try {
      const { data } = await new InstanceService().convertFileTo(
        objectId,
        deleteSource,
        objectType,
      );
      dispatch(addData({ [data.id]: data }));
      dispatch(addToList({ identity: 'storage', objectId: data.id, afterId: objectId }));
      if (deleteSource) {
        dispatch(removeFromList({ identity: 'storage', objectId }));
        dispatch(clearSelection());
      }
      return data;
    } catch (e) {
      dispatch(setAppLoading({ isOpen: false }));
      throw e;
    }
  },
);

export const downloadFile = createAsyncThunk(
  `${SLICE_NAME}/downloadFile`,
  async ({
    objectId,
    objectType,
    filename,
  }: Pick<ObjectParams, 'objectId' | 'objectType'> & { filename: string }) => {
    if (objectType === 'dopdf' || objectType === 'file') {
      const { data } = await new InstanceService().downloadFile(objectId, objectType);
      const name = filename;

      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      notify({
        type: 'success',
        title: 'ELEMENT_TYPE_DOWNLOADED',
        titleValues: { elementType: objectType.charAt(0).toUpperCase() + objectType.slice(1) },
        message: 'ELEMENT_TYPE_SUCCESSFULLY_DOWNLOADED',
        messageValues: { elementTitle: filename },
      });
    }
  },
);

export const downloadOriginalFile = createAsyncThunk(
  `${SLICE_NAME}/downloadOriginalFile`,
  async (
    {
      objectId,
      objectType,
      filename,
    }: Pick<ObjectParams, 'objectId' | 'objectType'> & { filename: string },
    { dispatch },
  ) => {
    if (objectType !== 'document' && objectType !== 'dopdf' && objectType !== 'presentation') {
      return;
    }

    dispatch(setAppLoading({ isOpen: true, message: 'DOWNLOADING_ORIGINAL_FILE' }));

    let blobData: Blob | null = null;
    let extension = '';
    switch (objectType) {
      case 'document':
        blobData = (await new InstanceService().downloadSource(objectId)).data;
        extension = '.docx';
        break;
      case 'dopdf':
        blobData = (await new InstanceService().downloadFile(objectId, 'dopdf')).data;
        extension = '.pdf';
        break;
      case 'presentation':
        blobData = (await new InstanceService().downloadPresentationSource(objectId)).data;
        extension = '.pptx';
        break;
    }

    if (blobData) {
      const name = filename.includes('.') ? filename.split('.').slice(0, -1).join('.') : filename;
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}${extension}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
);

export const deleteObject = createAsyncThunk(
  `${SLICE_NAME}/deleteObject`,
  async (
    {
      params,
      identity,
    }: { params: ObjectParams & { name: string } } & {
      identity: Table.Identity;
    },
    { dispatch },
  ) => {
    await new InstanceService().delete(params);
    if (identity) {
      dispatch(clearSelection());
      dispatch(removeFromList({ identity, objectId: params.objectId }));
    }

    dispatch(deletedObject({ objectId: params.objectId }));
    notify({
      type: 'success',
      title: 'DELETE_OBJECT_TITLE',
      titleValues: { type: params.objectType.charAt(0).toUpperCase() + params.objectType.slice(1) },
      message: 'DELETE_OBJECT_MESSAGE',
      messageValues: { name: params.name },
    });
  },
);

export const changeObjectManager = createAsyncThunk(
  `${SLICE_NAME}/changeObjectManager`,
  async (
    {
      objectId,
      objectType,
      params,
    }: ObjectParams & {
      params: { user: UserId; recursive: boolean };
    },
    { dispatch },
  ) => {
    try {
      await new InstanceService({ errorsExpected: [400, 403] }).changeManager(
        objectType,
        objectId,
        params,
      );

      dispatch(updateData({ objectId, data: { owner: params.user } }));
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 400) {
          notify({
            type: 'error',
            title: 'CANNOT_CHANGE_OWNER',
            message: 'CANNOT_CHANGE_OWNER_REASON',
          });
        }
        if (error.response?.status === 403) {
          notify({
            type: 'error',
            title: 'CANNOT_CHANGE_OWNER',
            message: 'CANNOT_CHANGE_OWNER_NO_PERMISSIONS',
          });
        }
      }
    }
  },
);

export const checkOutFile = createAsyncThunk(
  `${SLICE_NAME}/checkOutFile`,
  async ({ objectId }: { objectId: ObjectId }, { dispatch }) => {
    const { data } = await new InstanceService().checkOutFile(objectId);
    dispatch(updateData({ objectId, data }));
    notify({
      type: 'success',
      title: 'global.success',
      message: 'storage.notifications.checkOut.messageSuccess',
    });
  },
);

export const cancelCheckOut = createAsyncThunk(
  `${SLICE_NAME}/cancelCheckOut`,
  async ({ objectId }: { objectId: ObjectId }, { dispatch }) => {
    try {
      const { data } = await new InstanceService({ errorsExpected: [403] }).checkOutFileClose(
        objectId,
      );

      dispatch(updateData({ objectId, data }));
      notify({
        type: 'success',
        title: 'global.success',
        message: 'storage.notifications.closeCheckOut.messageSuccess',
      });
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 403) {
          notify({
            type: 'error',
            title: 'notifications.noPermissions.title',
            message: 'notifications.noPermissions.message',
          });
        }
      }
    }
  },
);

export const moveObject = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  {
    sourcesId: ObjectId[];
    destination: AppSliceState['data'][keyof AppSliceState['data']];
    identity: Table.Identity;
  },
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(
  `${SLICE_NAME}/moveObject`,
  async ({ sourcesId, destination, identity }, { dispatch, getState }) => {
    const data = getState().app.data;
    const service = new InstanceService();

    const allRequests: ReturnType<typeof service.move>[] = [];

    sourcesId.forEach((sourceId) => {
      const params: {
        source: typeof sourceId;
        space?: (typeof destination)['id'];
        destination?: (typeof destination)['id'];
      } = {
        source: sourceId,
      };
      if (destination.type === 'space') {
        params.space = destination.id;
      } else {
        params.destination = destination.id;
      }

      allRequests.push(service.move(params));
    });

    Promise.allSettled(allRequests).then((results) => {
      const getType = (
        array: (PromiseSettledResult<Request.AxiosResponse<unknown>> & { index: number })[],
      ) => {
        let type = '';
        const firstElem = data[sourcesId[array[0].index]];
        if (array.length === 1) {
          type = firstElem.type;
        } else {
          let isDifferentType = false;
          isDifferentType = array.every((element: (typeof array)[number]) => {
            return firstElem.type === data[sourcesId[element.index]].type;
          });

          if (!isDifferentType) {
            type = 'items';
          } else {
            type = `${firstElem.type}s`;
          }
        }
        return type;
      };

      const r = results.map((result, index) => ({ ...result, index }));
      const failedArray = r.filter((result) => result.status === 'rejected');
      const successArray = r.filter((result) => result.status === 'fulfilled');

      if (failedArray.length !== 0) {
        const objsType = getType(failedArray);
        notify({
          type: 'error',
          title: 'FAILED_TO_MOVE_TITLE',
          titleValues: { type: objsType },
          message: 'FAILED_TO_MOVE_DESC',
          messageValues: {
            total: failedArray.length + '',
            destName:
              destination.personal && destination.type === 'space' ? 'My Files' : destination.name,
            destType: destination.type,
          },
        });
      } else {
        const objsType = getType(successArray);
        notify({
          type: 'success',
          title: 'MOVED_TITLE',
          titleValues: { type: objsType },
          message: 'MOVED_DESC',
          messageValues: {
            total: successArray.length + '',
            destName:
              destination.personal && destination.type === 'space' ? 'My Files' : destination.name,
            destType: destination.type,
          },
        });
      }

      //@ts-expect-error Missing endpoint type "/api/object/${source}/move"
      const movedIds = successArray.map((element) => element.value.data.id);
      dispatch(removeFromList({ identity, objectId: movedIds }));
      dispatch(clearSelection());
    });
  },
);

export const copyObject = createAsyncThunk(
  `${SLICE_NAME}/copyObject`,
  async (
    {
      params,
      identity,
      current,
      destinationName,
    }: {
      params: {
        sources: ObjectId[];
        space?: ObjectId;
        destination?: ObjectId;
        name?: string;
        type?: string;
        keep_reviews: boolean;
      };
      identity: Table.Identity;
      current: Partial<Objekt>;
      destinationName: string;
    },
    { dispatch },
  ) => {
    dispatch(closeAndResetModal('SaveAsModal'));
    const { data } = await new InstanceService().copy(params);
    // @ts-expect-error Missing endpoint type "/api/object/copy"
    const newObj: Objekt[] = data.ok;
    newObj.forEach((obj) => dispatch(addData({ [obj.id]: obj })));

    if (identity) {
      if (params.space === current.id || params.destination === current.id) {
        // Added on current space (update Table list)
        newObj.forEach((obj) => {
          dispatch(addToList({ identity, objectId: obj.id }));
          dispatch(singleSelection({ identity, objectId: obj.id }));
        });
      }
    }

    if (params.sources.length > 1) {
      notify({
        type: 'success',
        title: 'ALL_ELEMENTS_COPIED',
        message: 'ALL_ELEMENTS_WERE_SUCCESSFULLY_COPIED',
        messageValues: {
          spaceTitle: destinationName,
        },
      });
    } else {
      notify({
        type: 'success',
        title: 'ELEMENT_X_COPIED',
        titleValues: {
          elementType: params.type
            ? params.type?.charAt(0).toUpperCase() + params.type?.slice(1)
            : '',
        },
        message: 'ELEMENT_X_WAS_SUCCESSFULLY_COPIED',
        messageValues: {
          elementType: params.type
            ? params.type?.charAt(0).toUpperCase() + params.type?.slice(1)
            : '',
          spaceTitle: destinationName,
        },
      });
    }
  },
);

export const renameObject = createAsyncThunk(
  `${SLICE_NAME}/renameObject`,
  async ({ objectId, objectType, newName }: ObjectParams & { newName: string }, { dispatch }) => {
    dispatch(updateModal({ modal: 'RenameObjectModal', data: { submitting: true } }));
    const { data } = await new InstanceService().rename(objectId, objectType, newName);
    dispatch(
      // @ts-expect-error Generic endpoint & Missing endpoint type "/api/object/${type}/${id}/edit"
      updateData({ objectId, data }),
    );
    dispatch(closeAndResetModal('RenameObjectModal'));
  },
);
// #endregion

// #region Element Version
export const uploadNewFileVersion = createAsyncThunk<
  // Return type of the payload creator
  void,
  // First argument to the payload creator
  { params: Pick<ObjectParams, 'objectId'> & { file: File } },
  // Types for ThunkAPI
  {
    state: RootState;
  }
>(`${SLICE_NAME}/uploadNewFileVersion`, async ({ params }, { dispatch, getState }) => {
  const config = {
    onUploadProgress: (progress: Request.AxiosProgressEvent) => {
      const percentage = (progress.loaded * 100) / (progress.total ?? 1);
      dispatch(
        updateModal({
          modal: 'CheckInModal',
          data: { uploadPercentage: percentage },
        }),
      );
    },
  };
  dispatch(
    updateModal({
      modal: 'CheckInModal',
      data: { loading: true },
    }),
  );

  try {
    const { data } = await new InstanceService({
      errorsExpected: [400],
    }).uploadNewFileVersion(params, getState().modals.CheckInModal.operation, config);

    // @ts-expect-error Generic endpoint
    const errors: { mime?: typeof data.mime; name?: typeof data.name } = {
      mime: undefined,
      name: undefined,
    };
    // @ts-expect-error Generic endpoint
    if (data.mime) {
      // @ts-expect-error Generic endpoint
      errors.mime = data.mime;
    }
    // @ts-expect-error Generic endpoint
    if (data.name) {
      // @ts-expect-error Generic endpoint
      errors.name = data.name;
    }
    const info = {
      errors,
      file: {
        // @ts-expect-error Generic endpoint
        name: data.name ? data.name.new : params.file.name,
        size: params.file.size,
      },
      // @ts-expect-error Generic endpoint
      id: data.id,
      loading: false,
    };
    dispatch(
      updateModal({
        modal: 'CheckInModal',
        data: info,
      }),
    );
  } catch (error) {
    if (InstanceService.isAxiosError(error)) {
      if (error.response?.status === 400) {
        if (error.response.data.file[0] === 'equal') {
          dispatch(
            updateModal({
              modal: 'CheckInModal',
              data: { loading: false },
            }),
          );
          notify({
            type: 'error',
            title: 'global.error',
            message: 'storage.notifications.uploadFile.message2',
          });
        }
      }
    }
  }
});

export const confirmNewFileVersion = createAsyncThunk(
  `${SLICE_NAME}/confirmNewFileVersion`,
  async (
    {
      objectId,
      params,
      operation,
    }: Pick<ObjectParams, 'objectId'> & {
      params: { name: string; version: string; comment: string; close: boolean };
      operation: 'update' | 'checkIn';
    },
    { dispatch },
  ) => {
    const { data } = await new InstanceService().confirmNewFileVersion(objectId, params, operation);
    // @ts-expect-error Generic endpoint
    dispatch(updateData({ objectId, data }));
    dispatch(closeAndResetModal('CheckInModal'));
    notify({
      type: 'success',
      title: 'global.success',
      message: 'storage.notifications.checkInFile.message2',
    });
  },
);
// #endregion

// #region Group Thunks
export const getGroupById = createAsyncThunk(
  `${SLICE_NAME}/getGroupById`,
  async (
    { groupId, skipErrors }: Pick<GroupParams, 'groupId'> & { skipErrors?: number[] },
    { dispatch },
  ) => {
    try {
      const { data } = await new GroupsService({
        errorsExpected: [400, 403, 404],
      }).getGroupById(groupId);

      const groupInfo = {
        [groupId]: { ...data },
      };
      dispatch(addData(groupInfo));
    } catch (error) {
      if (InstanceService.isAxiosError(error)) {
        if (error.response?.status === 400 && !skipErrors?.includes(400)) {
          dispatch(setBadRequestState());
        } else if (error.response?.status === 403 && !skipErrors?.includes(403)) {
          dispatch(setForbiddenState());
        } else if (error.response?.status === 404 && !skipErrors?.includes(404)) {
          dispatch(setNotFoundState());
        }
      }

      throw error;
    }
  },
);

export const addUserToGroup = createAsyncThunk(
  `${SLICE_NAME}/addUserToGroup`,
  async ({ groupId, userId }: Pick<GroupParams, 'groupId' | 'userId'>, { dispatch }) => {
    await new GroupsService().addUserToGroup(groupId, userId);
    dispatch(groupUserAdded({ groupId, userId }));
  },
);

export const removeUserFromGroup = createAsyncThunk(
  `${SLICE_NAME}/removeUserFromGroup`,
  async ({ groupId, userId }: Pick<GroupParams, 'groupId' | 'userId'>, { dispatch }) => {
    await new GroupsService().removeUserFromGroup(groupId, userId);
    dispatch(getGroupById({ groupId }));
  },
);

export const giveGroupPermission = createAsyncThunk(
  `${SLICE_NAME}/giveGroupPermission`,
  async ({ groupId, userId }: Pick<GroupParams, 'groupId' | 'userId'>, { dispatch }) => {
    await new GroupsService().giveGroupPermission(groupId, userId);
    dispatch(groupPermissionGiven({ groupId, userId }));
  },
);

export const removeGroupPermission = createAsyncThunk(
  `${SLICE_NAME}/removeGroupPermission`,
  async ({ groupId, userId }: Pick<GroupParams, 'groupId' | 'userId'>, { dispatch }) => {
    await new GroupsService().removeGroupPermission(groupId, userId);
    dispatch(getGroupById({ groupId }));
  },
);
// #endregion

// #region Metadata Thunks
export const editMetadata = createAsyncThunk(
  `${SLICE_NAME}/editMetadata`,
  async (
    { objectId, parameters }: Pick<ObjectParams, 'objectId'> & { parameters: MyAny },
    { dispatch },
  ) => {
    const { data } = await new InstanceService().editMetadata(objectId, parameters);
    dispatch(updateData({ objectId, data: { metadata: { ...data.metadata } } }));
  },
);
// #endregion

// #endregion

// #region Selectors
const getData = (state: RootState) => state.app.data;

export const selectIsIEnvision = createSelector(
  [(state: RootState) => state.app.information],
  (information) => {
    return information?.third_party && information.third_party.name === 'ienvision';
  },
);

export const selectCollaborators = createSelector(
  [
    getData,
    selectCurrentUser,
    (state: RootState) => state.onboarding.active.editor,
    (state: RootState) => state.onboarding.started.editor,
    (state: RootState) => state.editor.status.visible,
    (_: RootState, objectId: Objekt['id'], includeSelf = false) => [objectId, includeSelf],
  ],
  (
    data,
    { data: currentUser },
    onboardingIsActive,
    onboardingHasStarted,
    isEditor,
    [objectId, includeSelf],
  ) => {
    const object = data[objectId];
    if (!object) {
      return [];
    }

    const collaborators =
      isEditor && (onboardingIsActive || onboardingHasStarted)
        ? [GHOST_USERS.davidBean['id']]
        : uniq([...(object?.users || []), ...(object?.shared_with || []), object.owner]);

    return (
      includeSelf
        ? collaborators
        : collaborators.filter((collaboratorId) => collaboratorId !== currentUser?.profile.id)
    ) as UserId[];
  },
);

export const selectShareModalValues = createSelector(
  [getData, (state: RootState) => state.modals.ShareModal.objectId],
  (data: AppSliceState['data'], objectId: ObjectParams['objectId']) => {
    let object = null;
    let usersList: UserId[] = [];
    let groupsList: Group['id'][] = [];
    if (data[objectId]) {
      // Avoid preventExtensions set by immer
      object = data[objectId];
    }

    if (object && object.status !== 'processing') {
      usersList = [
        ...Object.keys(object.permissions && object.permissions.users),
        ...Object.keys(
          (object.permissions &&
            object.permissions.roles &&
            object.permissions.roles.users &&
            object.permissions.roles.users) ||
            object.document.permissions.roles.users,
        ),
      ];
      groupsList = [
        ...Object.keys(object.permissions && object.permissions.groups),
        ...Object.keys(
          object.permissions &&
            object.permissions.roles &&
            object.permissions.roles.groups &&
            object.permissions.roles.groups,
        ),
      ];

      usersList.sort();
      groupsList.sort();

      usersList = [`${object.owner}` || `${object.document.owner}`, ...usersList];

      usersList = uniq(usersList);
      groupsList = uniq(groupsList);
    }
    return { usersList, groupsList };
  },
);

export const isChangeStatusEnabled = createSelector(
  [selectUserIsAdmin, getData, selectElementStatusList, (_: RootState, id: ObjectId) => id],
  (userIsAdmin, data: AppSliceState['data'], statusList, id) => {
    const object = data[id];

    if (!object) {
      return {};
    }

    if (!object.user_permissions.includes('owner') && !userIsAdmin) {
      return { errorId: 'NO_PERMISSIONS_FOR_STATUS_CHANGE' as TranslationId };
    }

    if (!object?.parent || !data[object.parent]) {
      return {};
    }

    if (!statusList.find((status) => status.id === data[object.parent].status)?.allow_edit) {
      return { errorId: 'CANNOT_CHANGE_STATUS_BEFORE_PARENT_STATUS_CHANGE' as TranslationId };
    }

    return {};
  },
);

// #endregion

// #region Slice
const appSlice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    addData: (state, action: PayloadAction<AppSliceState['data']>) => {
      state.data = Object.assign(state.data, action.payload);
    },
    updateData: (
      state,
      action: PayloadAction<
        Pick<ObjectParams, 'objectId'> & {
          data: Partial<AppSliceState['data'][keyof AppSliceState['data']]>;
        }
      >,
    ) => {
      const { objectId, data } = action.payload;

      const newData = { ...state.data[objectId], ...data };
      if (!(state.data[objectId], isEqual(newData, state.data[objectId]))) {
        state.data[objectId] = newData;
      }
    },
    deletedObject: (state, action: PayloadAction<Pick<ObjectParams, 'objectId'>>) => {
      const { objectId } = action.payload;

      delete state.data[objectId];
    },

    citationsListed: (
      state,
      action: PayloadAction<{
        document: ObjectParams['objectId'];
        citations: AppSliceState['data'][keyof AppSliceState['data']]['citations'];
      }>,
    ) => {
      const { document, citations } = action.payload;

      if (state.data[document]) {
        state.data[document].citations = citations;
      }
    },
    groupUserAdded: (state, action: PayloadAction<GroupParams>) => {
      const { groupId, userId } = action.payload;

      state.data[groupId].users.push(userId);
    },
    groupPermissionGiven: (state, action: PayloadAction<GroupParams>) => {
      const { groupId, userId } = action.payload;

      state.data[groupId].permissions.users[userId] = ['owner'];
    },
    resetAppState: () => {
      return INITIAL_STATE;
    },
    setAppLoading: (state, action: PayloadAction<AppSliceState['loading']>) => {
      state.loading = action.payload;
      if (!action.payload.isOpen) {
        delete state.loading.message;
        delete state.loading.messageValues;
      }
    },
    setRedirectValidSession: (
      state,
      action: PayloadAction<AppSliceState['redirectValidSession']>,
    ) => {
      state.redirectValidSession = action.payload;
    },
    deactivateTenant: (state) => {
      state.loading.isOpen = false;
      state.isTenantDeactivated = true;
      state.redirectValidSession = false;
    },
    setCurrentAppPage: (state, action: PayloadAction<AppSliceState['currentPage']>) => {
      state.currentPage = action.payload;
    },
    setAppInformation: (state, action: PayloadAction<AppSliceState['information']>) => {
      state.information = action.payload;
    },
    // #region Platform System
    updateVersionWarning: (
      state,
      action: PayloadAction<{ isOpen: AppSliceState['versionWarning']['isOpen'] }>,
    ) => {
      const { isOpen } = action.payload;

      state.versionWarning.isOpen = isOpen;
    },
    storeAdblockUsage: (state, action: PayloadAction<AppSliceState['adblock']>) => {
      const using = action.payload;

      state.adblock = using;
    },
    storePlatformInfo: (state, action: PayloadAction<AppSliceState['platform']>) => {
      state.platform = action.payload;
    },
    setForbiddenState: (state) => {
      state.error.status = 403;
    },
    setNotFoundState: (state) => {
      state.error.status = 404;
    },
    setBadRequestState: (state) => {
      state.error.status = 400;
    },
    setCleanState: (state) => {
      state.error.status = 0;
    },
    addUnknownObject: (state, action: PayloadAction<keyof AppSliceState['unknownObjects']>) => {
      state.unknownObjects[action.payload] = action.payload;
    },
    setConnectivityIssuesState: (
      state,
      action: PayloadAction<AppSliceState['connectivityIssues']>,
    ) => {
      state.connectivityIssues = action.payload;
    },
    setVersionValidation: (state, action: PayloadAction<AppSliceState['versionValidation']>) => {
      state.versionValidation = action.payload;
    },
    // #endregion
    setAppTheme: (state, action: PayloadAction<AppSliceState['theme']>) => {
      state.theme = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listObjects.fulfilled, (state, action) => {
      if (action.payload.dataStorage === 'app') {
        state.data = Object.assign(state.data, action.payload.dict);
      }
    });
    builder.addCase(lazyLoad.fulfilled, (state, action) => {
      if (action.payload.dataStorage === 'app') {
        state.data = Object.assign(state.data, action.payload.data);
      }
    });
    builder.addMatcher(
      isAnyOf(
        objectApi.endpoints.getObject.matchFulfilled,
        convertFileTo.fulfilled,
        ObjectApi.endpoints.getObject2.matchFulfilled,
      ),
      (state, action) => {
        if ('id' in action.payload) {
          state.data[action.payload.id] = {
            ...state.data[action.payload.id],
            ...action.payload,
          } as Objekt;
        }
      },
    );
    builder.addMatcher(isAnyOf(objectApi.endpoints.getObject.matchRejected), (state, action) => {
      const id = action.meta.arg.originalArgs?.objectId;
      if (id && action.payload?.status === 404) {
        state.unknownObjects[id] = id;
      }
    });
    builder.addMatcher(isAnyOf(ObjectApi.endpoints.getObject2.matchRejected), (state, action) => {
      const id = action.meta.arg.originalArgs?.object_id;
      if (id && action.payload?.status === 404) {
        state.unknownObjects[id] = id;
      }
    });
    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.getCurrentUser.matchFulfilled,
        authApi.endpoints.loginSetup.matchFulfilled,
      ),
      (state) => {
        state.loading.isOpen = false;
      },
    );
    builder.addMatcher(isAnyOf(authApi.endpoints.getTokenInfo.matchFulfilled), (state, action) => {
      const { actions, extra, third_party } = action.payload;
      state.information = { actions, extra, third_party };
    });
    builder.addMatcher(objectApi.endpoints.addRoles.matchFulfilled, (state, action) => {
      const { objectId, params } = action.meta.arg.originalArgs;

      const object = state.data[objectId];
      if (!object) {
        return;
      }

      const view = params.user ? 'users' : 'groups';
      const idKey = params.user ? 'user' : 'group';
      const permissions = object.permissions.roles[view];

      const key = params[idKey];

      if (key) {
        if (permissions[key]) {
          permissions[key].push(...params.roles);
        } else {
          permissions[key] = params.roles;
        }
      }
    });
    builder.addMatcher(objectApi.endpoints.removeRoles.matchFulfilled, (state, action) => {
      const { objectId, params } = action.meta.arg.originalArgs;

      const object = state.data[objectId];
      if (!object) {
        return;
      }

      const view = params.user ? 'users' : 'groups';
      const idKey = params.user ? 'user' : 'group';
      const roles = object.permissions.roles[view];

      const key = params[idKey];

      if (key) {
        if (roles[key]) {
          roles[key] = roles[key].filter((role: MyAny) => {
            if (params.roles) {
              return !params.roles.includes(role);
            }
            return false;
          });

          // If no longer has roles
          if (roles[key].length === 0) {
            delete roles[key];
          }
        }
      }
    });
    builder.addMatcher(
      objectApi.endpoints.changeElementStatus.matchFulfilled,
      (state, { payload }) => {
        //Related to: https://dodoccorp.atlassian.net/browse/DDC-10161
        //To avoid losing editor related data, keep non-overwritten properties
        state.data[payload.id] = { ...state.data[payload.id], ...payload };
      },
    );
  },
});

// #endregion

// #region Actions
export const {
  addData,
  updateData,
  deletedObject,
  citationsListed,
  groupUserAdded,
  groupPermissionGiven,
  resetAppState,
  setAppLoading,
  setRedirectValidSession,
  deactivateTenant,
  setCurrentAppPage,
  setAppInformation,
  updateVersionWarning,
  storeAdblockUsage,
  storePlatformInfo,
  setForbiddenState,
  setNotFoundState,
  setBadRequestState,
  setCleanState,
  addUnknownObject,
  setConnectivityIssuesState,
  setVersionValidation,
  setAppTheme,
} = appSlice.actions;
// #endregion

const persistConfig = {
  key: 'app',
  storage: storageSession,
  whitelist: ['information', 'versionValidation'],
};

const appReducer = persistReducer(persistConfig, appSlice.reducer);

export default appReducer;
