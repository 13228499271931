import axios from 'axios';
import networkEvents from '../api/events';

export const axiosResponseSuccessInterceptor = (response: Request.AxiosResponse) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
};

export const axiosResponseErrorInterceptor = (errorsExpected: number[]) => (error: any) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // First, check if this wasn't cancelled by us
  if (!axios.isCancel(error)) {
    if (error.message === 'Network Error') {
      networkEvents.emit('NETWORK_ERROR');
    } else if (!errorsExpected.includes(error.response?.status)) {
      switch (error.response?.status) {
        // tenant not found
        case 496:
          // networkEvents.emit('TENANT_NOT_FOUND');
          // different errors, same handler
          networkEvents.emit('UNAUTHORIZED', error);
          break;
        case 401: {
          networkEvents.emit('UNAUTHORIZED', error);
          break;
        }
        case 400:
          networkEvents.emit('BAD_REQUEST', error);
          break;
        case 403:
          networkEvents.emit('FORBIDDEN', error);
          break;
        case 404:
          networkEvents.emit('NOT_FOUND', error);
          break;
        case 427:
          networkEvents.emit('FORCE_PASSWORD_RESET', error);
          break;
        case 428:
          networkEvents.emit('UNKNOWN_DEVICE', error);
          break;
        case 540:
          networkEvents.emit('TENANT_DEACTIVATED');
          break;
        default:
          networkEvents.emit('GENERIC_ERROR', error);
      }
    }

    return Promise.reject(error);
  }
};
// Add a response interceptor
axios.interceptors.response.use(axiosResponseSuccessInterceptor, axiosResponseErrorInterceptor);
