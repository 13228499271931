import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactAvatarEditor from 'react-avatar-editor';
import { Modal, Button, Upload, ProgressBar } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';

import { closeAndResetModal } from '_common/modals/ModalsSlice';

import styles from './ChangeAvatarModal.module.scss';
import { useUploadAvatarMutation } from 'Auth/redux/avatarApi';

const MODAL = 'ChangeAvatarModal';

const ChangeAvatarModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.modals.open[MODAL]);
  const userId = useSelector((state) => state.auth.userId);

  const [percentage, setPercentage] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const [scale, setScale] = useState(1);
  const [avatarData, setAvatarData] = useState({ name: '', size: 0 });
  const [image, setImage] = useState<File>();
  const [editorRef, setEditorRef] = useState<ReactAvatarEditor | null>(null);
  const [uploadAvatar] = useUploadAvatarMutation();

  const close = useCallback(() => {
    setScale(1);
    setAvatarData({ name: '', size: 0 });
    setImage(undefined);
    setEditorRef(null);
    dispatch(closeAndResetModal(MODAL));
  }, [dispatch]);

  const handleSetPercentage = ({
    loaded,
    total,
  }: Pick<Request.AxiosProgressEvent, 'loaded' | 'total'>) => {
    setPercentage(Math.floor((loaded * 100) / (total ?? 1)));
  };

  const uploadProgressChanged = useCallback(
    (values: Pick<Request.AxiosProgressEvent, 'loaded' | 'total'>) => {
      handleSetPercentage(values);
    },
    [],
  );

  useEffect(() => {
    if (!isOpen) {
      setScale(1);
      setAvatarData({ name: '', size: 0 });
      setImage(undefined);
    }
    if (percentage === 100) {
      setPercentage(0);
      setDisabled(true);
      close();
    }
  }, [close, dispatch, isOpen, percentage]);

  const handleUploadAvatar = () => {
    if (editorRef) {
      editorRef.getImageScaledToCanvas().toBlob(async (blob) => {
        if (blob) {
          uploadAvatar({ avatar: blob, userId, onUploadProgress: uploadProgressChanged });
        }
      });
    }
  };

  const handleScale: ChangeEventHandler<HTMLInputElement> = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handleOnUpload = (_: React.DragEvent<HTMLDivElement>, acceptedFiles: File[]) => {
    if (acceptedFiles) {
      setImage(acceptedFiles[0]);
      setAvatarData({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    }
  };

  const handleOnButtonUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const acceptedFiles = e.target.files;

    if (acceptedFiles) {
      setImage(acceptedFiles[0]);
      setAvatarData({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    }
  };

  return (
    <Modal width="68rem" open={!!isOpen} onClose={close} testId="change-avatar">
      <Modal.Header onClose={close}>
        <FormattedMessage id="storage.modals.uploadUserAvatar.title" />
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          {avatarData.name === '' && (
            <Upload
              onDrop={handleOnUpload}
              accept="image/*"
              text={intl.formatMessage({
                id: 'DRAG_AND_DROP_AVATAR',
              })}
              testId="avatar"
            />
          )}
          {avatarData.name !== '' && (
            <div className={styles.editAvatarUpload}>
              <ReactAvatarEditor
                ref={setEditorRef}
                width={256}
                image={image || ''}
                height={256}
                border={0}
                borderRadius={200}
                scale={scale}
                style={{ marginTop: '32px', borderRadius: '200px' }}
              />
              {percentage === 0 ? (
                <>
                  <div className={styles.zoom}>
                    <div className={styles.textDrop}>
                      <FormattedMessage id="storage.modals.uploadUserAvatar.zoom" />
                    </div>
                    <input
                      min="1"
                      max="2"
                      step="0.01"
                      name="scale"
                      type="range"
                      defaultValue="1"
                      className={styles.slider}
                      onChange={handleScale}
                      style={{ outline: '0' }}
                      data-testid="avatar-zoom-input"
                    />
                  </div>
                  <div className={styles.button}>
                    <Button
                      size="medium"
                      variant="primary"
                      upload
                      accept="image/*"
                      onChange={handleOnButtonUpload}
                      testId="upload-avatar-button"
                    >
                      <FormattedMessage id="storage.modals.uploadUserAvatar.uploadNewImage" />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.avatarInfo}>
                    <FormattedMessage id="PHOTO_UPLOADING_PLEASE_WAIT" />
                  </div>
                  <ProgressBar
                    progress
                    progressValue={percentage}
                    testId="change-avatar-modal-uploading-progressBar"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="change-avatar-modal-cancel">
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={handleUploadAvatar}
          disabled={avatarData.name !== '' ? !disabled : disabled}
          loading={percentage > 0 && percentage < 100}
          testId="change-avatar-modal-submit"
        >
          <FormattedMessage id="storage.modals.uploadUserAvatar.updateProfile" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAvatarModal;
