import { TypedEmitter } from 'tiny-typed-emitter';

interface NetworkEvents {
  NETWORK_ERROR: () => void;
  TENANT_NOT_FOUND: () => void;
  UNAUTHORIZED: (error: Request.AxiosError) => void;
  BAD_REQUEST: (error: Request.AxiosError) => void;
  FORBIDDEN: (error: Request.AxiosError) => void;
  NOT_FOUND: (error: Request.AxiosError) => void;
  FORCE_PASSWORD_RESET: (error: Request.AxiosError<{ token: string }>) => void;
  UNKNOWN_DEVICE: (
    error: Request.AxiosError<{ token: string; device: string; username: string; id: string }>,
  ) => void;
  TENANT_DEACTIVATED: () => void;
  GENERIC_ERROR: (error: Request.AxiosError) => void;
}

const networkEvents = new TypedEmitter<NetworkEvents>();

export default networkEvents;
