export class MMLHelper {
  static latexToMML(latex: string): string {
    return MathJax.tex2mml(latex);
  }

  static mmlToNode = (mml: string | null | undefined): HTMLElement | undefined => {
    if (!mml) {
      throw new Error(`MMLHelper: mmlToNode - Invalid mml \n${mml}`);
    }

    const errorElementSelector = '.noError';
    const rootElement = MathJax.mathml2svg(mml);

    const mmlError = rootElement.querySelector(errorElementSelector);

    if (mmlError) {
      throw new Error(`MMLHelper: mmlToNode - Invalid mml \n${mmlError.outerHTML}`);
    }

    return rootElement;
  };

  static appendMMLToElement = (mml: string | null | undefined, element: HTMLElement) => {
    try {
      const node = MMLHelper.mmlToNode(mml);

      if (node) {
        element.appendChild(node);
        MathJax.startup.document.clear();
        MathJax.startup.document.updateDocument();
      }
    } catch (error) {
      throw error;
    }
  };
}
